import classes from './haveAccount.module.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
const HaveAccount = ({togglePage,isSignUp}) => {
  const cookieName = 'jwtPW';

 function removeCookie(name) {
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
 }

  
 async function logOut() {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_API_HOST}/members/logout`, {
      timeout: 5000,
      withCredentials: true,
    });
    console.log("response in logout", resp);
  } catch (error) {
    console.error("Logout request failed", error);
  } finally {
    localStorage.clear();
    sessionStorage.clear();
    removeCookie(cookieName);
    
  }
}

const handleSignInClick = async (e) => {
  e.preventDefault();
  await logOut();
};


  return (
    <div className="d-flex align-items-center justify-content-center">
      {isSignUp ? (
        <>
         
          <p className={classes.account}>
            Already have an account?{' '}
            <NavLink to="/" onClick={() => togglePage(false)} onMouseDown={handleSignInClick} style={{color:"#3a3838"}}>
              Sign in 
            </NavLink>
          </p>
        </>
      ) : (
        <>
    
          <p className={classes.account}>
            Don't have an account?{' '}
            <NavLink to="/signup" onClick={() => togglePage(true)} style={{color:"#3a3838"}}>
              Sign up here
            </NavLink>
          </p>
        </>
      )}

    </div>
  );
};

  

export default HaveAccount;