import React, {useState, createContext, useEffect } from "react";
import axios from "axios";
const AuthContext = createContext();


const cookieName = 'jwtPW';
 function removeCookie(name) {
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
 }

 function AuthContextProvider(props) {
    const [loggedIn,setLoggedIn] = useState(false);
    console.log("Logged In ",loggedIn);

    async function getLoggedIn(){
      try{
        const loggedInRes = await axios.get(
            `${process.env.REACT_APP_API_HOST}/members/loggedin`);
             setLoggedIn(loggedInRes.data);
             console.log("logged In API",loggedInRes.data)
             //console.log("Logged In ",loggedIn);
      }catch(err){
        console.log(err);
        setLoggedIn(false);
      } 
    }
    
    async function logOut() {
      try {
        const resp = await axios.get(`${process.env.REACT_APP_API_HOST}/members/logout`,{
           timeout:5000,
           withCredentials:true,
         });
         console.log("response in logout", resp);
       } catch (error) {
         console.error('Logout request failed', error);
       } finally {
         await getLoggedIn();
         localStorage.removeItem('token');
         localStorage.removeItem('username');
         localStorage.clear();
         sessionStorage.clear();
         removeCookie(cookieName);
     
         
       }
      
    };
    useEffect(()=>{
        getLoggedIn();
    },[]);

    return(
       <AuthContext.Provider value={{loggedIn, getLoggedIn,logOut}}>
         {props.children}
       </AuthContext.Provider>
    );
}

export default AuthContext;
export {AuthContextProvider};