import ReactDOM from 'react-dom';
import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import errorpage from './errorpage/errorpage';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import { ApiProviders } from './context/ApiContext';
import io from 'socket.io-client';

const ENDPOINT ='https://api.prayer-weaver.net'


const socketOptions = {
  reconnection:true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 10000,
  forceNew: true,
  transports: ['websocket','polling'],
};



export const socket = io(ENDPOINT, socketOptions);
ReactDOM.render(
<React.StrictMode> 
    <ApiProviders>
    <ErrorBoundary FallbackComponent={errorpage}>
     <App/> 
</ErrorBoundary>
</ApiProviders>

</React.StrictMode>,

document.getElementById('root')


);
console.log("exit insex.js");
