 import classes from './LoginIcon.module.css';
 import React,{useState,useEffect,useContext} from "react";
 import {Link} from 'react-router-dom'
 import 'bootstrap/dist/css/bootstrap.min.css';
 import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { S3Client } from '@aws-sdk/client-s3';
import nonProfileIcon from '../images/nonIcon.png'
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import config from "../../config";
import axios from 'axios';
const apiUrl = config();

const key = 'DO00PFUZFUPP9QY8YX4F';
const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
const spaceName = 'prayer-weaver-files';
const region = 'ams3';
const host = `${spaceName}.${region}.digitaloceanspaces.com`;

const client = new S3Client({
  region,
  endpoint: `https://${host}`,
  credentials: {
    accessKeyId: key,
    secretAccessKey: secret,
  },
  bucketEndpoint: true,
 
});



const LoginIcon = () =>{
  const [username, setUsername] = useState('');
  const [profilePic, setProfilePic] = useState(localStorage.getItem('user-image'));
  console.log('Profile image URL:', localStorage.getItem('user-image'));
  const { getLoggedIn } = useContext(AuthContext);
 //localStorage.removeItem('token');
 const navigate = useNavigate();
 const cookieName = 'jwtPW';

 function removeCookie(name) {
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
 }

 async function logOut(e) {
  e.preventDefault();
  try {
   const resp = await axios.get(`${process.env.REACT_APP_API_HOST}/members/logout`,{
      timeout:5000,
      withCredentials:true,
    });
    console.log("response in logout", resp);
  } catch (error) {
    console.error('Logout request failed', error);
  } finally {
    await getLoggedIn();
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.clear();
    sessionStorage.clear();
    removeCookie(cookieName);

    navigate("/");
  }
 
 }

  useEffect(() => {
    setUsername(localStorage.getItem('user-name') || '');
    //setProfilePic(localStorage.getItem('user-image') || '');
    const handleStorageChange = () => {
      setProfilePic(localStorage.getItem('user-image'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const imgUrl = profilePic ? `https://${host}/member/${profilePic}` : nonProfileIcon;
  
     return (
     

      <div id={classes.content} className='justify-content-center align-self-center'>
        
       
      
      <Dropdown as={ButtonGroup}>
      <Button style={{backgroundColor:'transparent',borderColor:'transparent' }}> <img src={imgUrl} style={{width:46,height:43, borderRadius:50,marginLeft:110}} className={classes.iconP}/></Button>

      <Dropdown.Toggle style={{backgroundColor:'transparent',borderColor:'transparent',  marginLeft:-15}}  />

      <Dropdown.Menu className={classes.dropDown} style={{marginTop:-47}}>
      
        <Dropdown.Item
  id={classes.menu}

  as={Link}
  to="/profile"
  className={classes.menuItem} // Add a custom class for styling
>
 My profile ({username})
</Dropdown.Item>
          <Dropdown.Item href="/" onClick={logOut} className={classes.menuItem}>logout </Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>
       
       
       </div>
     )

 }
 export default LoginIcon;