import React from 'react';
import {NavLink} from 'react-router-dom';
import classes from './Navigation.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Navigation = () => {
  return (
    <nav className={classes.nav}>
      <ul>
       
          <li>
            <NavLink to="/Main/UrgentPrayers"  style={({ isActive }) =>
              isActive
                ? {
                  color:'#E05D31',
                  textDecoration:'underline',
                  fontWeight:700,
                  }
                : { color: '#545e6f', background: '#f0f0f0' }
            }>Prayer Feeds</NavLink>
          </li>
          <li>
            <NavLink to="/Main/NewsPrayers" style={({ isActive }) =>
              isActive
                ? {
                  color:'#E05D31',
                  textDecoration:'underline',
                  fontWeight:700,
                  }
                : { color: '#545e6f', background: '#f0f0f0' }
            }  >News</NavLink>
          </li>
          <li>
            <NavLink to="/Main/PopularPrayers" style={({ isActive }) =>
              isActive
                ? {
                  color:'#E05D31',
                  textDecoration:'underline',
                  fontWeight:700,
                  }
                : { color: '#545e6f', background: '#f0f0f0' }
            }  >Popular</NavLink>
          </li>
          <li>
            <NavLink to="/Main/MakeStories" style={({ isActive }) =>
              isActive
                ? {
                  color:'#E05D31',
                  textDecoration:'underline',
                  fontWeight:700,
                  }
                : { color: '#545e6f', background: '#f0f0f0' }
            } >Stories</NavLink>
          </li>
        
      </ul>
    </nav>
  );
};

export default Navigation;
