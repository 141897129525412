import React,{useState,useEffect,useCallback} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import classes from './UrgentPrayer.module.css';
import MainHeading from "../../UI/MainHead/MainHeading";
import icon from '../../notification/IconText.module.css';
import axios from 'axios';
import PrayerText from "../../UI/MainHead/PrayerText";
import Loading from "../../UI/Loading/Loadding";
import Modal from '../../UI/Modal/Modal';
import ResponseB from "../../notification/feedback/ResponseB";
import { socket } from '../../../index';
import UpdatedPrayer from "./UpdatedPrayer";
import classses from '../../notification/Notification.module.css';
import { useApi } from '../../../context/ApiContext';
import config from "../../../config";
import { S3Client } from '@aws-sdk/client-s3';
import nonProfilPic from '../../images/nonIcon.png';
import ConfirmModal from "../../UI/Modal/ConfirmModal";

  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,

  });
const apiUrl = config();
const isProduction = process.env.NODE_ENV === 'production';
  const serverUrl = isProduction ? process.env.REACT_APP_ASSET_URL : process.env.REACT_APP_ASSET_URL_LOCAL;

const MinePrayers= () =>{
  const {deactivatePrayer,updatedPrayerId} = useApi();
const [minePrayer,setMinePrayer] = useState([]);
const [selectedPostId, setSelectedPostId] = useState(null);
const [likescount, setLikesCount] = useState(0);
const [isModalOpened,setIsModalOpened] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
const [loading,setloading] = useState(false);
const [prayerToDelete, setPrayerToDelete] = useState(null);
const userId = localStorage.getItem("user-id");
var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const url = `${process.env.REACT_APP_API_HOST}/prayers/myprayers/${userId}`;
const updateImageUrls = (data) => {
  return data.map((prayerItem) => {
    const prayerImg = prayerItem.image;
    const profileImg = prayerItem.userId.AddPic;

    const imageUrl = prayerImg ? `https://${host}/prayer/${prayerImg}` : null;
    const profileImageUrl = profileImg ? `https://${host}/member/${profileImg}` : null;
    console.log('Profile Image URL:', profileImageUrl);
    return {
      ...prayerItem,
      imageUrl: imageUrl,
      profileImageUrl: profileImageUrl,
    };
  });
};
const fetchMinePrayer = async () => {
  try {
    setloading(true);
    const responseData = await axios.get(url);
    console.log(responseData);
    const data = responseData.data;
    
    if (data.prayers.length > 0) {
      const updatedprayer = updateImageUrls(data.prayers);
      setMinePrayer(updatedprayer);
      console.log("updated mine pryaer",updatedprayer);
    } else {
      setMinePrayer([]);
    }
    
  } catch (err) {
    console.log(err);
  }
  finally {
    console.log(loading,"test");
    setTimeout(() => {
      setloading(false);
    }, 1500);
  }
};
useEffect(() => {
  fetchMinePrayer();  
  socket.connect();
  console.log("UseEffect Socket.io PostAdded", socket);

  socket.on("connect", () => {
    console.log("Socket connected successfully");
  });
  socket.on("deactivatepryer", (data) => {
    setMinePrayer((prevPrayers) => prevPrayers.filter(prayer => prayer._id !== data._id));
  });
  const updateImageUrls = (newPosts) => ({
    ...newPosts,
    imageUrl: `https://${host}/prayer/${newPosts.image}`,
    imageUrl2: `https://${host}/member/${newPosts.userId?.AddPic}`
  });
socket.on('likeAdded', (newPosts) => {
  setMinePrayer((prevpryrs) => {
    const updated = prevpryrs.map((prayerr) => prayerr._id === newPosts._id ? updateImageUrls(newPosts)  : prayerr);
    return updated;
  });
});
socket.on('unlikeAdded', (newPosts) => {
  setMinePrayer((prevPosts) => {
    const updated = prevPosts.map((prayerr) => prayerr._id === newPosts._id ? updateImageUrls(newPosts) : prayerr);
    return updated; 
   
  });
});


  return () => {
 
    socket.off("likeAdded");
    socket.off("unlikeAdded");
    socket.off("deactivatepryer");
  };

}, [socket]);

const deactivateP = async (prayerId) => {
  try {
    await deactivatePrayer(prayerId);
    console.log("deactivate prayer");
    //setSelectedPost(postData); // Set the selected post data in state
  } catch (err) {
    console.log(err);
  }
};
const handleDeleteClick = (prayerId) => {
  setPrayerToDelete(prayerId);
  setIsModalOpen(true);
};

const confirmDelete = () => {
  deactivateP(prayerToDelete);
  setIsModalOpen(false);
  setPrayerToDelete(null);
};

const closeModal = () => {
  setIsModalOpen(false);
  setPrayerToDelete(null);
};
const handleClick =useCallback ((postId,action) => {
  setSelectedPostId(postId);
  updatedPrayerId(postId);
  if (action === 'edit') {
    setSelectedPostId(postId);
    setIsModalOpened(true);
    console.log("Selected Post Id:", postId);
  } else if (action === 'delete') {
    //setSelectedPostId(postId);
    handleDeleteClick(postId);
    //deactivateP(postId);
  } else if (action === 'like') {
    setSelectedPostId(postId);
  }
 // if (event.target.classList.contains('EditPrayer')) {
    // setSelectedPostId(postId);
    // setIsModalOpened(true);
    // console.log("Selected Post Id:", postId);
  //}
  console.log("prayer ID Mine Prayer2", selectedPostId);
},[]);
// const handleClick = (postId,action) => {
//   setSelectedPostId(postId);

//   if (action === 'edit') {
//     setSelectedPostId(postId);
//     setIsModalOpened(true);
//     console.log("Selected Post Id:", postId);
//   } else if (action === 'delete') {
//     handleDeleteClick(postId);
//   } else if (action === 'like') {
//     setSelectedPostId(postId);
//   }

//   console.log("prayer ID Mine Prayer2", selectedPostId);
// };
const updatedLikesCount =(newLikesCount) =>{
  setLikesCount(newLikesCount);
   console.log("likes inside prayer page",likescount);
   };
console.log("state variable", minePrayer);
return (
  <div className="d-flex justify-content-center">
    <div>
    {   loading ? ( // Show loading spinner while data is being fetched
        <Loading />
      ) :
      
      
      minePrayer !== undefined && minePrayer.length > 0 ? (
        <div>
          {minePrayer.map((pryers, index) => (
            <div id={classes.Prayer} className={'mt-3 mb-3 bg-image'} key={index}  prayerId={pryers._id}>
              <div className={'col mt-3'}>
                {/* <UserPic className={'justify-content-between'} /> */}
                <img src={pryers.profileImageUrl || nonProfilPic} style={{width:46,height:43, marginTop:10 , borderRadius:50}}/>
                <div className="ms-4">{pryers.userId && pryers.userId.username && (<p style={{marginTop:-43,marginLeft:30,fontFamily:'calibri',fontSize:16,fontWeight:700,color:'black'}}>{pryers.userId.username}</p>
) }
{new Date(pryers.createdAt) instanceof Date && !isNaN(new Date(pryers.createdAt)) ? (
    <span style={{marginLeft:30,position:'relative',top:-20,fontFamily:'calibri',fontSize:14,fontWeight:600,color:'black',opacity:0.3}}>
      {new Date(pryers.createdAt).toLocaleString(undefined, dateOptions)}
    </span>
  ) : (
    <span>Invalid Date</span>
  )}
  </div>
              </div>
              <div style={{marginLeft:-22}}>
              <MainHeading>{pryers.prayerTitle} </MainHeading>
              <PrayerText className={'justify-content-center'}>{pryers.prayerdesc}</PrayerText>
              </div>
              
              <img src={pryers.imageUrl} style={{width:500}} className="d-block mx-auto mt-4" />
              <div className={'row'}>
                <div className={'col mt-2 mb-2'} style={{marginLeft:-25}}>
                  <ResponseB style={{marginLeft: 78}} prayerId={pryers._id} likes={pryers.likes} updatedLikesCount={updatedLikesCount} />
                  <p className={icon.IconF}style={{marginLeft:128, marginTop:-42, color:"black", fontFamily: "Calibri", opacity: 0.5,}}>{pryers.likes.length}</p>
                </div>
                <div className={'col mt-3 mb-2'}>
                  <button  className={classses.EditPrayer} onClick={() => handleClick(pryers._id,'edit')}/>
                  <Modal isOpened={isModalOpened} onClose={() => setIsModalOpened(false)}>
                   <UpdatedPrayer prayerid={selectedPostId} />
                  </Modal>
                </div>
                <div className={'col mt-2 mb-2'}>
                <button className="btn bg-transparent" id={classses.deletePrayer} onClick={() => handleClick(pryers._id, 'delete')}/>
                <ConfirmModal
                     isOpen={isModalOpen}
                    onClose={closeModal}
                   onConfirm={confirmDelete}
                    message="Are you sure you want to delete this prayer?"
      />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No records found</p>
        )}
       
    </div>
  </div>
);
};

export default MinePrayers;