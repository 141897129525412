import React, { useContext } from "react";
import {BrowserRouter, Routes, Route,Navigate} from "react-router-dom";
import SignUpForm from './Components/SignupFP/SignUpForm';
import RegisterPge from './Components/Registerpge/RegisterPge';
import Main from "./Components/Main/Main";
import MinePrayer from './Components/PrayerType/UrgentPrayer/MinePrayer';
import PrayerReqSend from "./Components/Registerpge/PrayerReqSend";
import PrayerReqGet from "./Components/Registerpge/PrayerReqGet";
import AuthContext from "./context/AuthContext";
import UrgentPrayers from "./Components/PrayerType/UrgentPrayer/UrgentPrayers";
import SavedPrayer from "./Components/PrayerType/UrgentPrayer/SavedPrayer";
import NewsPrayers from "./Components/PrayerType/UrgentPrayer/NewsPrayers";
import PopularPrayers from "./Components/PrayerType/UrgentPrayer/PopularPrayers"
import MakeStories from "./Components/PrayerType/UrgentPrayer/MakeStories";
import PrayerReqq from "./Components/Registerpge/PrayerReqq";
import CompletePost from "./Components/PrayerType/UrgentPrayer/CompletePost";
import SinglePost from "./Components/PrayerType/UrgentPrayer/SinglePost";
import SingleNew from "./Components/PrayerType/UrgentPrayer/SingleNew";
import ForgotPassword from './Components/forgetpassword/ForgotPassword';
import  NewsReqSend  from "./Components/Registerpge/NewsReqSend";
import ResetPassword from "./Components/forgetpassword/ResetPassword";
import Massage from "./Components/SignupFP/Massage";
import Profille from "./Components/Registerpge/Profille";


function Router() {
  
  const { loggedIn } = useContext(AuthContext);
console.log("Router loggedIn",loggedIn);

  return (
    <BrowserRouter>
     
      <Routes>

     
      <Route path="/signup" element={<SignUpForm/>}/>
      <Route path="/Message" element={<Massage/>}/>
  
           {loggedIn === false && (
            <>
            <Route path = "/" element={ <RegisterPge/>}/>
            <Route path="/forgetpassword" element={<ForgotPassword/>}/>
            <Route path="/resetpassword/:id/:token" element={<ResetPassword/>}/>
            </>
            )}
            {loggedIn === true && (
              
              <>
               <Route path="/" element={<Navigate to="/main" />} />
               <Route path="/forgetpassword" element={<Navigate to="/main" />} />
            <Route path="/main" element={<Main/>}/>
            <Route path="/Main/PrayerReqSend" element={<PrayerReqSend/>}/>
            <Route path="/Main/NewsReqSend" element={< NewsReqSend/>}/>
            <Route path="/Main/UrgentPrayers" element={<UrgentPrayers/>}/>
            <Route path="/Main/SavedPrayer" element={<SavedPrayer/>}/>
            <Route path="/Main/MinePrayers" element={<MinePrayer/>}/>
            <Route path="/singlepost/:prayerid" element={<SinglePost/>}/>
            <Route path="/singlenew/:NewsId" element={<SingleNew/>}/>
            <Route path="/CompletePost" element={<CompletePost/>}/>
            <Route path="/PrayerReqq" element={<PrayerReqq/>}/> 
            <Route path="/Main/NewsPrayers" element={<NewsPrayers/>}/>
            <Route path="/prayerReqGet" element={<PrayerReqGet/>}/>
            <Route path="/Main/PopularPrayers" element={<PopularPrayers/>}/>
            <Route path="/Main/MakeStories" element={<MakeStories/>}/>
            <Route path="/profile" element={<Profille/>}/>
            
            
            </>
            )}
            {/* <Route path="*" element={<Navigate to={loggedIn ? "/main" : "/"} replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
