import classes from './PrayerStory.module.css';
import React,{useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useApi } from "../../../context/ApiContext";
import { useEffect,useRef,useReducer} from "react";
import ReelPost from "../prayerStory/ReelPost";
import Modal from "../../UI/Modal/Modal";
import { S3Client } from '@aws-sdk/client-s3';
import profile  from '../../images/nonIcon.png';
import { socket } from '../../../index';



const key = 'DO00PFUZFUPP9QY8YX4F';
const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
const spaceName = 'prayer-weaver-files';
const region = 'ams3';
const host = `${spaceName}.${region}.digitaloceanspaces.com`;

const client = new S3Client({
  region,
  endpoint: `https://${host}`,
  credentials: {
    accessKeyId: key,
    secretAccessKey: secret,
  },
  bucketEndpoint: true,

});


const LeftMenu = ({onUserSelect})=>{
  const {reelp,setReelp,allReel} = useApi();
 const isMounted = useRef(true);
 const [isModalOpened,setIsModalOpened] = useState(false);
 const [profilePic, setProfilePic] = useState(localStorage.getItem('user-image'));
 console.log('Profile image URL:', localStorage.getItem('user-image'));
 useEffect(() => {
   
   const handleStorageChange = () => {
     setProfilePic(localStorage.getItem('user-image'));
   };

   window.addEventListener('storage', handleStorageChange);

   return () => {
     window.removeEventListener('storage', handleStorageChange);
   };
 }, []);
 const imgUrl = `https://${host}/member/${profilePic}` ? `https://${host}/member/${profilePic}` : profile;
  const AllReel = async ()=>{
        try{
         const reeldata = await allReel();
          
          if (isMounted.current) {
          //   //setReelP(resp);
         console.log("leftMenu",reeldata);
          }
          //setloading(true);
        }catch(err){
            console.log(err);
        }

    }
    useEffect(() => {
      AllReel();
      return () => {
        isMounted.current = false;
      };
    }, []);
  
    const [, updateState] = useReducer((state) => state + 1, 0);
    useEffect(() => {
      updateState();
    }, [reelp]); // Re-run effect when reelP changes
  
    console.log("Reels Out", reelp);
   
    useEffect(() => {
      const handlePostAdded = (data) => {
        setReelp((prevPosts) => {
          // Check if the reel for the same user already exists
          const isExisting = prevPosts.some((post) => post.user?._id === data.user?._id);
          if (!isExisting) {
            // If not existing, add the new reel to the beginning
            return [data, ...prevPosts];
          } else {
            // If existing, replace the old reel with the new data
            return prevPosts.map((post) =>
              post.user?._id === data.user?._id ? data : post
            );
          }
        });
        // setReelp((prevPosts) => {
        //   // Check if the reel for the same user already exists
        //   const isExisting = prevPosts.some((post) => post.user?._id === data.user?._id);
        //   if (!isExisting) {
        //     // If not existing, add the new reel to the beginning
        //     return [data, ...prevPosts];
        //   } else {
        //     // If existing, replace the old reel with the new data
        //     return prevPosts.map((post) =>
        //       post.user._id === post.user?._id ? data : post
        //     );
        //   }
        // });
      };
      socket.connect();
  
      socket.on("reelAdded",handlePostAdded);
  
      return () => {
        socket.off("reelAdded", handlePostAdded);
        //socket.disconnect();
      };
    }, []);
// useEffect(() => {
//   console.log("Updated reelp:", reelp);
// }, [reelp]);
 return(
    <div>
     <ul className={"nav flex-column nav-pills"}>
      <h4>Your Story</h4>
      
        <div>
  <li className={"nav-item"} id={classes.clickable}>
  <div className='d-flex'>
       
        
       
       <img src={imgUrl} style={{width:46, height:43, borderRadius:50}} alt="" /> 
       {/* id={classes.StoryImg} */}
       <div className='d-flex flex-column mt-2' style={{marginLeft:15}}>
       <div id={classes.IconP} onClick={()=>setIsModalOpened(true)} >Create your Story</div>  
       <Modal isOpened={isModalOpened} onClose ={()=>setIsModalOpened(false)}>
              <ReelPost setIsModalOpened={setIsModalOpened}/>
            </Modal>    {/*<p>create link here to create new Prayer reel</p>*/}
       </div>
       </div>
  {/* <img className={classes.StoryImg} alt=""/>
  <a variant="nav-link" className={classes.color}  href="">Create Your Story</a> */}
  </li>

  
         <h4>All Stories</h4>
  {reelp && reelp.length > 0 ? (
     <div>
       {reelp.map((item,index) => (
    <div key={index} onClick={() => onUserSelect(item.user?._id)}>
 
    <li className="nav-item mt-2" id={classes.clickable}>
       <div className='d-flex'>
       <img
  src={item.user?.AddPic ? `https://${host}/member/${item.user.AddPic}` : profile}
  style={{ width: 46, height: 43, borderRadius: 50 }}
  alt={item.user?.username || "Profile"}
/>                                                            


         <div className='d-flex flex-column'>
         <div id={classes.IconP} className='mt-2 ms-3'>{item.user?.username}</div>
         </div>
      </div>
  
    </li>
    
      </div>
      
))}   
</div>

  ):<p>No data Available</p> }
  </div>
  </ul>
  </div>
  )

   }

export default LeftMenu;